import '../filters/unsafe';

angular.module('phpniLoader', ["unsafeFilter"]).directive('rdNgLoader', function () {
    return {
        restrict: 'E',
        template: "<div style=\"width:100%\">" +
            '<div ng-class="containerClass" class="rd-ng-loader-container">' +
            '<div class="rd-ng-loader-container-background"></div>' +
            '<div class="rd-ng-loader" ng-style="loaderStyle">' +
            '<img ng-if="src" ng-src="{{src}}" ng-style="svgStyle" />' +
            '<!-- http://samherbert.net/svg-loaders/ -->' +
            '<svg ng-if="!src" ng-style="svgStyle" width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff" preserveAspectRatio="xMidYMid meet">' +
            '+<g fill="none" fill-rule="evenodd">' +
            '<g transform="translate(1 1)" stroke-width="2">' +
            '<circle stroke-opacity=".5" cx="18" cy="18" r="18"/>' +
            '<path d="M36 18c0-9.94-8.06-18-18-18">' +
            '</path>' +
            '</g>' +
            '</g>' +
            '</svg>' +
            '<p ng-if="text" ng-bind-html="text | unsafe"></p>' +
            '</div>' +
            '</div>' +
            '<div ng-class="backgroundContainerClass" class="rd-ng-loader-container-background">' +
            '</div>',
        replace: true,
        scope: {
            text: '=?',
            height: '=?',
            containerHeight: '=?',
            icon: '=?',
            src: '=?',
            fullScreen: '=?',
            theme: '=?'
        },
        link: function (scope) {
            if (scope.icon) {
                if (!scope.containerHeight) scope.containerHeight = '40px';
                if (!scope.height) scope.height = '30px';
            } else if (!scope.height) scope.height = '80px';

            // Container
            scope.containerClass = {};
            if (scope.fullScreen) scope.containerClass['full-screen'] = scope.fullScreen;
            if (scope.icon) scope.containerClass['rd-ng-loader--icon'] = scope.icon;
            if (scope.theme == 'light') scope.containerClass['rd-ng-loader--theme-light'] = true;

            // Loader Style
            scope.loaderStyle = {};
            if (scope.containerHeight) {
                scope.loaderStyle['min-height'] = scope.containerHeight;
                scope.loaderStyle['max-height'] = scope.containerHeight;
                scope.loaderStyle['height'] = scope.containerHeight;
            }

            // Background Class
            scope.backgroundContainerClass = {};
            if (scope.fullScreen) {
                scope.backgroundContainerClass['full-screen'] = scope.fullScreen;
            }

            if (scope.theme == 'light') scope.backgroundContainerClass['rd-ng-loader--theme-light'] = true;

            // SVG Style
            scope.svgStyle = {};
            if (scope.height) {
                scope.svgStyle['margin-left'] = 'auto';
                scope.svgStyle['margin-right'] = 'auto';
                scope.svgStyle['min-height'] = scope.height + "px";
                scope.svgStyle['max-height'] = scope.height + "px";
                scope.svgStyle['height'] = scope.height + "px";
                scope.svgStyle['width'] = scope.height + "px";
            }

            if (scope.src && scope.src.indexOf('.gif') > -1) {
                scope.svgStyle['width'] = scope.height + "px";
                scope.svgStyle['max-width'] = scope.height + "px";
                delete scope.svgStyle['max-height'];
                scope.svgStyle['height'] = 'auto';
            }
        }
    };
});

angular.module('phpniLoader').run(["$rootScope",
    function ($rootScope) {

        $rootScope.loader = {
            show: false,
            fullScreen: false,
            count: 0,
            text: '',
            containerHeight: 700,
        };

        $rootScope.$on('http-loading-count', function (event, counter) {
            // $rootScope.loader.show = counter.templateLoader > 0;
            // $rootScope.loader.fullScreen = counter.fullScreenLoader > 0;
            $rootScope.loader.count = counter;
        });

    }]);

// Queue? https://stackoverflow.com/questions/14464945/add-queueing-to-angulars-http-service
// https://stackoverflow.com/a/42556454
angular.module('phpniLoader').factory('loaderHttpInterceptor', ["$rootScope", "$q", function ($rootScope, $q) {
    var counter = {
        templateLoader: 0,
        fullScreenLoader: 0
    };
    var pendingRequestsCounter = 0;
    var readMethods = ['GET'];
    var writeMethods = ['POST', 'PUT', 'DELETE', 'PATCH'];
    var shouldShowTemplateLoader = function (config) {
        if (!config) return false;
        if (config.templateLoader === true) return true;
        if (config.templateLoader === false || config.fullScreenLoader === true) return false;
        if (readMethods.indexOf(config.method) > -1) return true;
        return false;
    };
    var shouldshowFullScreenLoader = function (config) {
        if (!config) return false;
        if (config.fullScreenLoader === true) return true;
        if (config.templateLoader === true || config.fullScreenLoader === false) return false;
        if (writeMethods.indexOf(config.method) > -1) return true;
        return false;
    };
    var shouldIgnore = function (config) {
        if (!config) return false;
        if (config.ignoreLoader) return true;
        if (config.willRetry) return true;
        if (config.url.endsWith('.html')) return true;
        return false;
    };
    return {
        request: function (config) {
            if (!shouldIgnore(config)) {
                if (shouldShowTemplateLoader(config))
                    counter.templateLoader++;

                if (shouldshowFullScreenLoader(config))
                    counter.fullScreenLoader++;

                $rootScope.$broadcast('http-loading-count', counter);
            }
            return config;
        },
        response: function (response) {
            if (!shouldIgnore(response.config)) {
                if (shouldShowTemplateLoader(response.config))
                    counter.templateLoader--;

                if (shouldshowFullScreenLoader(response.config))
                    counter.fullScreenLoader--;

                $rootScope.$broadcast('http-loading-count', counter);
            }
            return response;
        },
        responseError: function (responseError) {

            if (!shouldIgnore(responseError.config)) {
                if (shouldShowTemplateLoader(responseError.config))
                    counter.templateLoader--;

                if (shouldshowFullScreenLoader(responseError.config))
                    counter.fullScreenLoader--;

                $rootScope.$broadcast('http-loading-count', counter);
            }

            return $q.reject(responseError);
        },
    };
}]);

angular.module('phpniLoader').config(["$httpProvider", function ($httpProvider) {
    $httpProvider.interceptors.splice(0, 0, 'loaderHttpInterceptor');
}]);
