angular.module('LoginApp').controller('LoginController',
    ["$scope", "$rootScope", "$window", "$location", "$cookies", "$timeout", "$http", "swangular", "$sce",
        function ($scope, $rootScope, $window, $location, $cookies, $timeout, $http, swangular, $sce) {

            $scope.form = {
                data: {},
                redirecting: false,
            };

            $scope.options = {
                forgot: false,
                error: ''
            };

            $scope.redirectionData = '';

            $scope.goToDashboard = function (replace) {
                $rootScope.goTo('/', false, replace);
            };

            $scope.parseSearch = function () {
                var search = $location.search();
                if (search.error) {
                    var error = search.error.toLowerCase();
                    if (error.indexOf('xpire') > -1) {
                        error = "Session Expired";
                    } else if (error.indexOf('session') > -1) {
                        error = "Invalid Session";
                    } else if (error.indexOf('automatically logged') > -1) {
                        error = "You were automatically logged out for your security.";
                    } else {
                        error = '';
                    }
                    $scope.options.error = error;
                }

                if (search.redirect) {
                    var hash = $location.hash();
                    var redirect = search.redirect;
                    if (hash && hash.length > 0) {
                        redirect += ("#" + hash)
                    }
                    if (redirect == '/') {
                        $location.search('redirect', null);
                    }
                    $scope.options.redirect = redirect;
                }
            };

            $scope.parseSearch();

            var parseForgot = () => {
                var path = $location.path();
                if (path.indexOf('forgot') > -1) {
                    $scope.options.forgot = true;
                } else {
                    $scope.options.forgot = false;
                }
            };

            $scope.$on('$locationChangeSuccess', parseForgot);
            parseForgot();

            $scope.openForgot = function (forgot) {
                $location.path(forgot ? '/forgot' : '/').replace();
            };

            // Get form values for each form and add csrf and portal values needed for every form
            $scope.getFormValues = function (type) {
                let csrf = document.getElementsByName('_csrf_token')[0].value;
                let portal = document.getElementsByName('portal')[0].value;
                let flag = document.getElementsByName('flag')[0].value;
                let body = {
                    '_csrf_token': csrf,
                    'portal': portal,
                    'flag': flag,
                    'redirect': $scope.options.redirect,
                };

                if (type === 'login') {
                    body.username = $scope.form.data.username;
                    body.password = $scope.form.data.password;
                    if ($scope.options.pin) {
                        body.pinnumber = $scope.form.data.pinnumber;
                    }
                } else if (type === 'forgot') {
                    if ($scope.options.pin) {
                        body.email = $scope.form.data.pinemail;
                    } else {
                        body.email = $scope.form.data.username;
                    }
                }

                return body;
            };

            $scope.login = function () {
                $scope.options.error = '';
                $location.search('error', null);

                var body = $scope.getFormValues('login');

                if ((!body.username || !body.password) || ($scope.options.pin && !body.pinnumber)) { // do nothing if no required values
                    return;
                }

                $scope.loginRequest = $http.post('/authenticate', body, {
                    headers: {'Content-Type': 'application/json'},
                    ignoreRetry: true
                }).then(function (res) {

                    if (!res.data) throw Error;
                    if (!res.data.success) throw Error;

                    var results = res.data;

                    // Check to see if they need to re-register
                    /* if (message.indexOf('must complete registration') > -1) {
                         if (results.email && results.email.length) $window.sessionStorage.setItem('email', results.email);
                         $rootScope.goTo('/login/#!/registration/?message='+encodeURIComponent('re-register')+"&linkeduserid="+results.linkeduserid);
                         return;
                     }

                     // Check if they need to verify their email
                     if (message.indexOf('verified') > -1 || (results.hasOwnProperty('verifiedemail') && !$scope.bool(results.verifiedemail))) {
                         $window.sessionStorage.setItem('email', $scope.form.data.username);
                         $rootScope.goTo(`/login/#!/registration/success?message=${encodeURIComponent('needs-verification')}&src=${encodeURIComponent('login')}`);
                         return;
                     }

                     // Check to see if novudemouser
                     if (results.hasOwnProperty('novudemouser') && $scope.bool(results.novudemouser)) {
                         if ($window.location.hostname.indexOf('phpni.com') > -1) {
                             $cookies.put('_PHPNOVU_', results.sessionid, {path: '/', domain: 'phpni.com'});
                             $window.location.href = "https://phpni.novu.com"
                         } else {
                             swangular.swal("Error", "Novu demo user not allowed to login. Please use https://member.phpni.com.", "error");
                         }
                         return;
                     }*/

                    if (!res.data.redirect) {
                        $scope.options.redirect = '/dashboard';
                    } else {
                        $scope.options.redirect = res.data.redirect;
                    }

                    $scope.processLogin();
                }).catch(function (err) {
                    $scope.processingLogin = false;
                    $scope.config.redirecting = false;

                    switch (err.data.action) {
                        case 're-register':
                            if (body.username && body.username.length) $window.sessionStorage.setItem('email', body.username);
                            $window.location.replace('/registration/' + body.portal + '#!/?linkeduserid=' + err.data.value);
                            break;
                        case 'verify_email':
                            $window.sessionStorage.setItem('email', body.username);
                            swangular.open({
                                title: 'Verify Account',
                                text: 'This account needs to be verified.',
                                icon: 'error',
                                closeOnClickOutside: false,
                                closeOnEsc: true,
                                buttons: ["Close", "Verify Account"],
                            }).then(function (verifyAccount) {
                                if (verifyAccount) {
                                    $window.location.replace('/registration/' + body.portal + '#!/success?code=');
                                }
                            });
                            break;
                        case 'pinnumber':
                            $scope.options.error = err.data.message || 'PIN Number required';
                            $scope.options.pin = true;
                            break;
                        case 'error':
                            swangular.swal("Error", "Unable to submit login.", "error");
                            break;
                        default:

                    }

                    if (!err.data.message) {
                        console.error(err);
                        swangular.swal("Error", "Unable to submit login.", "error");
                    } else {
                        $scope.options.error = err.data.message;
                    }

                }).finally(function () {
                    if (!$scope.processingLogin)
                        $scope.loginRequest = false;
                });
            };

            $scope.redirect = function () {
                $scope.config.redirecting = true;

                if ($scope.validRedirect()) {
                    if ($scope.options.redirect.indexOf('/') === 0) {
                        $window.location.replace($scope.options.redirect);
                    } else if ($scope.options.redirect.indexOf('//') === 0) {
                        $window.location.replace($scope.options.redirect);
                    }
                }
            };

            $scope.validRedirect = function () {

                if (!$scope.options.redirect) return false;

                var validDomains = [
                    $window.location.hostname,
                    'phpni.com',
                ];

                var self = $scope.getDomain($window.location.href);
                var dest = $scope.getDomain($scope.options.redirect);

                // No domain and relative path
                if (!dest && $scope.options.redirect.indexOf('/') === 0) {
                    return true;
                }

                // Same domain or trusted
                if ((self && self === dest) || validDomains.indexOf(dest) > -1 || $scope.options.redirect.indexOf('//') > -1) {
                    return true;
                }

                return false;
            };

            $scope.getHostName = function (url) {
                var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
                if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
                    return match[2];
                } else {
                    return null;
                }
            };

            $scope.getDomain = function (url) {
                var urlToMatch = url;
                if (urlToMatch.indexOf("//") == 0) {
                    urlToMatch = ":" + urlToMatch;
                }
                var hostName = $scope.getHostName(urlToMatch);
                var domain = hostName;

                if (hostName != null) {
                    var parts = hostName.split('.').reverse();

                    if (parts != null && parts.length > 1) {
                        domain = parts[1] + '.' + parts[0];

                        if (hostName.toLowerCase().indexOf('.co.uk') != -1 && parts.length > 2) {
                            domain = parts[2] + '.' + domain;
                        }
                    }
                }

                return domain;
            };

            $scope.forgot = function () {
                $scope.options.error = '';

                var body = $scope.getFormValues('forgot');

                if (!body.email) { // do nothing if no required values
                    return;
                }

                $scope.forgotRequest = $http.post('/api/account/forgotpassword', body, {headers: {'Content-Type': 'application/json'}}).then(function (res) {
                    swangular.swal("Success", "Your password change request has been submitted and an email has been sent to you.\n\nIf you do not receive the email within 60 minutes, please contact PHP Sales at 260-432-6690.", "success");
                }).catch(function (err) {
                    if (err.data.message) {
                        swangular.swal('Error', err.data.message, 'error');
                    } else {
                        swangular.swal("Error", "Unable to submit forgot password.", "error");
                    }
                    console.error(err);
                }).finally(function () {
                    $scope.forgotRequest = false;
                });
            };

            $scope.processLogin = function () {
                $scope.processingLogin = true;
                $scope.config.redirecting = true;
                $scope.redirect();

                /* LoginService.getUserInfo(sessionID).then((res)=>{
                     if ($scope.bool(res.data.results.viewedonboarding)) {
                         $scope.redirect();
                     } else {
                         $scope.goTo('/account-tutorial/', false, true);
                     }
                 }).catch((err)=>{
                     console.error(err);

                 }).finally(()=>{

                 });*/
            };

            $scope.submit = function () {
                if ($scope.options.forgot) $scope.forgot();
                else $scope.login();
            };
        }]
);

