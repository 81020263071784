import 'angular';
import 'angular-route';
import 'angular-cookies';
import 'angular-conditional-validation';
import 'ng-focus-if';
import swal from 'sweetalert';
import 'swangular';
import 'ng-mask';
import 'ngPluralizeFilter';
import '../directives/phpniLoader';
import '../directives/formErrorLabel';
import '../directives/formFieldWrapper';
import '../directives/passwordRules';

angular.module('LoginApp', [
    'ngRoute',
    'angularConditionalValidation',
    'ngCookies',
    'focus-if',
    'ngMask',
    'swangular',
    'ngPluralizeFilter',
    'phpniLoader',
    'errorLabel',
    'fieldWrapper',
    'passwordRules',
    'compareTo',
]);

angular.module('LoginApp').config(["$locationProvider", "$routeProvider", function ($locationProvider, $routeProvider) {
    $routeProvider
        .when('/', {
            templateUrl: "/login.html",
            reloadOnSearch: false
        }).when('/forgot', {
        templateUrl: "/login.html",
        reloadOnSearch: false
    }).when("/reset", {
        templateUrl: "/reset.html",
        controller: 'ResetPasswordController',
        reloadOnSearch: false,
    }).otherwise({
        controller: ["$window", "$location", function ($window, $location) {
            var current = $location.path();
            $location.path('/');
            $location.search('redirect', current);
        }],
        template: "<div></div>",
    });
}]);

angular.module('LoginApp').run(["$rootScope", "$location", "$window", "$timeout", "$interval", "$http",
    function ($rootScope, $location, $window, $timeout, $interval, $http) {

        $rootScope.content = {
            title: 'Login',
            portal: 'Employer'
        };

        var parseTitle = () => {
            var path = $location.path();
            if (path.indexOf('sign-up') > -1) {
                $rootScope.content.title = 'Sign Up';
            } else if (path.indexOf('/registration') > -1) {
                $rootScope.content.title = 'Registration';
            } else if (path == '/') {
                $rootScope.content.title = 'Login';
            } else if (path.indexOf('reset') > -1) {
                $rootScope.content.title = 'Reset Password';
            } else if (path.indexOf('forgot') > -1) {
                $rootScope.content.title = 'Forgot Password';
            } else {
                $rootScope.content.title = '';
            }
        };

        $rootScope.$on('$locationChangeSuccess', parseTitle);

        $rootScope.setPortal = function (portal) {
            $rootScope.content.portal = portal;
        };

        $rootScope.firstTimeLoad = function () {
            $rootScope.user = false;
            $rootScope.config = {};
            parseTitle();
        };

        $rootScope.changeLocation = function (path) {
            $location.path(path);
        };

        $rootScope.safeApply = function (fn) {
            $timeout(function () {
                if (fn) {
                    $rootScope.$apply();
                } else {
                    $rootScope.$apply(fn);
                }
            }, 0);
        };

        $rootScope.firstTimeLoad();
    }]);
