angular.module('LoginApp').controller('ResetPasswordController',
    ["$scope", "$rootScope", "$window", "$location", "$http", "swangular", "$timeout",
        function ($scope, $rootScope, $window, $location, $http, swangular, $timeout) {

            $scope.form = {
                data: {},
            };

            $scope.options = {
                error: ''
            };

            $scope.goToLogin = function () {
                $rootScope.goTo('/login', false, true);
            };

            $scope.parseSearch = function () {
                let search = $location.search();
                if (!search) {
                    const uri = $window.location.href;
                    const queryString = {};
                    uri.replace(
                        new RegExp("([^?=&]+)(=([^&]*))?", "g"),
                        function ($0, $1, $2, $3) {
                            queryString[$1] = $3;
                        }
                    );
                    search = queryString;
                }
                if (search && search.code) {
                    $scope.form.data.verificationcode = search.code;
                } else {
                    $scope.options.error = "Invalid Reset Code";
                }
            };

            $scope.closeTooltipIfNeeded = function () {
                if (!$scope.form.validation.password.$error.passwordRules) $scope.form.$showPasswordTooltip = false
            };

            $scope.$on('$locationChangeSuccess', function () {
                $scope.parseSearch();
            });

            $scope.parseSearch();

            $scope.submit = function () {
                $scope.options.error = '';

                const csrf = document.getElementsByName('_csrf_token')[0].value;
                const portal = document.getElementsByName('portal')[0].value;

                const body = {
                    'password': $scope.form.data.password,
                    'confirmpassword': $scope.form.data.confirmpassword,
                    'verificationcode': $scope.form.data.verificationcode,
                    'portal': portal,
                    '_csrf_token': csrf
                };

                $http.put('/api/account/resetpassword', body).then(function (res) {
                    if (res.data.results) {
                        const results = res.data.results;
                        if (!results.successful) {
                            swangular.swal("Error", results.results, "error");
                            return;
                        }

                        $location.search('code', null);
                        $timeout(function(){
                            $scope.options.error = "";
                        });

                        swangular.open({
                            title: "Password Reset",
                            text: "Please login with your new password.",
                            icon: "success",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                            button: "LOGIN",
                        }).then(function (isConfirm) {
                            $window.location.href = "/login/" + portal;
                        });
                        return;
                    }
                    swangular.swal("Unknown Error", "Unable to reset password. Pleas try again later.", "error");
                }).catch(function (err) {
                    if (err.data.errors && err.data.errors.length) {
                        err.data.errors.map((error) => {
                            if (error.field === 'VerificationCode') {
                                $scope.options.error = 'Reset code invalid. Please open the link in the email and try again. If that doesn\'t work, please submit another request to reset your password.';
                            }
                        });

                        if ($scope.options.error) return;
                    }
                    swangular.swal("Error", "Unable to reset password. Pleas try again later.", "error");
                    console.error(err);
                });
            };
        }
    ]
);
